.xlArr {
  @apply hidden md:block w-48 h-48;
}

.lgArr {
  @apply hidden md:block w-12 h-12 md:w-24 md:h-24;
} 

.smArr {
  @apply hidden md:block w-16 h-16;
}

.xlArr, .lgArr, .smArr {
  @apply m-0;
}