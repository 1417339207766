@import "bootstrap-icons/font/bootstrap-icons.css";

:root {
  --primary: rgb(226, 106, 34);
  --primary-light: rgb(232, 136, 78);
  --primary-extra-light: rgb(240, 180, 144);
  --secondary: rgba(6, 140, 193, 0.25);
  --secondary-light: rgb(225, 225, 223);
  --secondary-dark: rgb(96, 96, 96);

  --min-minmax-sm: 180px;
  --min-minmax-md: 250px;
  --min-minmax-base: 300px;
  --min-minmax-lg: 420px;
  --min-minmax-xl: 480px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  font-family: Montserrat, sans-serif;
  margin: 0;
  background-color: hsla(0, 0%, 100%, 0.8);
  color: hsl(0, 0%, 0%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fecha {
  text-align: right;
  margin-right: 20px;
}

/* Grid columns auto fit */
.grid-cols-auto-fit-sm {
  grid-template-columns: repeat(auto-fit, minmax(var(--min-minmax-sm), 1fr));
}

.grid-cols-auto-fit-md {
  grid-template-columns: repeat(auto-fit, minmax(var(--min-minmax-md), 1fr));
}

.grid-cols-auto-fit-base {
  grid-template-columns: repeat(auto-fit, minmax(var(--min-minmax-base), 1fr));
}

.grid-cols-auto-fit-lg {
  grid-template-columns: repeat(auto-fit, minmax(var(--min-minmax-lg), 1fr));
}

.grid-cols-auto-fit-xl {
  grid-template-columns: repeat(auto-fit, minmax(var(--min-minmax-xl), 1fr));
}


/* Grid rows auto fit */
.grid-rows-auto-fit-sm {
  grid-template-rows: repeat(auto-fit, minmax(var(--min-minmax-sm), 1fr));
}

.grid-rows-auto-fit-md {
  grid-template-rows: repeat(auto-fit, minmax(var(--min-minmax-md), 1fr));
}

.grid-rows-auto-fit-base {
  grid-template-rows: repeat(auto-fit, minmax(var(--min-minmax-base), 1fr));
}

.grid-rows-auto-fit-lg {
  grid-template-rows: repeat(auto-fit, minmax(var(--min-minmax-lg), 1fr));
}

.grid-rows-auto-fit-xl {
  grid-template-rows: repeat(auto-fit, minmax(var(--min-minmax-xl), 1fr));
}


@page {
  size: auto;
  margin: 0;
  padding: 0;
}